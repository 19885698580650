/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useWindowWidthContext } from '@/contexts/WindowWidthContext/WindowWidthContext';

import Modal from '../Modal';
import PopOverModal from '../PopOverModal';

const ResponsiveModal = ({ children, ...props }) => {
  const { isGtEqMd } = useWindowWidthContext();

  return isGtEqMd ? (
    <Modal {...props}>{children}</Modal>
  ) : (
    <PopOverModal {...props}>{children}</PopOverModal>
  );
};

export default ResponsiveModal;
